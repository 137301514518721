import React from 'react'
import { Navigation } from '../../../common/navigation'
import { GlobalHelmet } from '../../../common/seo/globalHelmet'
import { MatchingNavigation } from '../../../common/matching-navigation'
import { Icon } from '../../../common/icon'
import { MatchingQuestion } from '../../../common/matching-question'
import { MatchingAnswers } from '../../../common/matching-answers'
import { AREA_CARE_PATH } from '../../../config/constants'

export default function Frage35() {
  const question = {
    title: 'Frage 35 von 35',
    headline: '',
    subline:
      'Welche Art der Kommunikation magst du am liebsten? (Teamkommunikation)',
  }

  return (
    <>
      <GlobalHelmet title={`UKE jukebox - ${question.title}`} />
      <main className="bg-img-yellow-triangles">
        <Navigation />

        <section className="quiz">
          <div className="row text-center">
            <MatchingQuestion
              color="yellow"
              headline={question.headline}
              subline={question.subline}
            />
            <MatchingAnswers
              type="range"
              color="green"
              from="Durch die Blume gesprochen."
              to="Direkt und frei heraus."
              index={35}
            />
          </div>
        </section>

        <MatchingNavigation
          color="yellow"
          current={question.title}
          next={`${AREA_CARE_PATH}/spielen/gewichtung-4`}
          prev={`${AREA_CARE_PATH}/spielen/frage-34`}
          index={35}
        />

        <Icon position="1" name="flower" />
        <Icon position="3" name="megaphone" />
      </main>
    </>
  )
}
